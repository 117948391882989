<template>
  <div>
    <core-page-title />

    <v-container class="mt-3">
      <v-row>
        <v-col>
          <v-btn color="success" class="mr-2" dark @click="openDialog()">
            <v-icon left v-text="'mdi-plus'" />
            Adicionar
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col>
          <v-sheet elevation="1">
            <v-data-table
              disable-filtering
              disable-sort
              :server-items-length="count"
              :loading="loading"
              :headers="headers"
              :items="obituary"
              :options.sync="pagination"
              :footer-props="{
                'items-per-page-options': [10, 20, 50],
              }"
              @update:options="loadObituary"
            >
              <template
                v-slot:[`item.birthDate`]="{ item }"
              >
                {{ item.birthDate | date }}
              </template>
              <template
                v-slot:[`item.deathDate`]="{ item }"
              >
                {{ item.deathDate | date }}
              </template>
              <template v-slot:[`item.actions`]="{ item }" class="text-right">
                <v-btn icon small @click="openDialog(item)">
                  <v-icon v-text="'mdi-pencil'" small />
                </v-btn>
                <v-btn icon small>
                  <v-icon
                    v-text="'mdi-delete'"
                    small
                    @click="clickDeleteItem(item)"
                  />
                </v-btn>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <dialog-obituary
      v-model="showDialogProduct"
      :obituary-id="editId"
      @save="loadObituary"
    />

    <dialog-delete
      v-model="showDialogDelete"
      :loading="loadingDelete"
      @confirm="deleteItem"
    />
  </div>
</template>

<script>
import obituaryApi from '@/api/obituary'

export default {
  components: {
    DialogDelete: () => import('@/components/admin/dialog/DialogDelete'),
    DialogObituary: () =>
      import('@/components/admin/dialog/obituary/DialogObituary'),
  },

  data() {
    return {
      loading: false,
      loadingDelete: false,
      showDialogProduct: false,
      showDialogDelete: false,
      count: 0,
      deleteId: null,
      editId: null,
      headers: [
        { text: '#', value: 'id' },
        { text: 'Nome', value: 'name' },
        { text: 'Nascimento', value: 'birthDate' },
        { text: 'Falecimento', value: 'deathDate' },
        { text: '', value: 'actions', align: 'right' },
      ],
      obituary: [],
      pagination: {},
    }
  },

  methods: {
    async loadObituary() {
      try {
        this.loading = true

        const response = await obituaryApi.list({
          limit: this.pagination.itemsPerPage,
          offset:
            this.pagination.page * this.pagination.itemsPerPage -
            this.pagination.itemsPerPage,
        })

        this.obituary = response.data.obituary
        this.count = response.data.count
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loading = false
      }
    },

    openDialog(obituary = null) {
      this.editId = obituary ? obituary.id : null
      this.showDialogProduct = true
    },

    async clickDeleteItem(obituary) {
      this.deleteId = obituary.id
      this.showDialogDelete = true
    },

    async deleteItem() {
      try {
        this.loadingDelete = true

        await obituaryApi.delete(this.deleteId)

        this.showDialogDelete = false
        this.loadObituary()

        this.$snackbar.show({
          color: 'success',
          message: this.$messages._('delete_success'),
        })
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingDelete = false
        this.dialogDelete = false
      }
    },
  },
}
</script>
